
<div class="container-fluid d-flex justify-content-center">
  <form class="login form-row" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" >
    <div class="card" style="width: 50rem;">
      
      <div class="card-body">
        <div class="col-12 d-flex justify-content-center logo pt-5">
          <img src="../../../assets/img/png/logoExterna.png" alt="">
        </div>
        <div class="d-flex justify-content-center logo">
          <strong style="text-align: center;color:#919294 !important;font-weight: 900 !important;" class="p-2  col-lg-12">GERENCIAL</strong>
        </div>
        <div class="pt-5">
          <h6 class="bold mb-1">Fale conosco</h6>
          <p>Dúvidas, sugestões ou reclamações?
            Entre em contato e retornaremos pelo e-mail
            informado!
            </p>
        </div>
        <div class="col-12 form-group pt-5">
          <label class="col-12 form-group">
            <input class="input__field form-control" placeholder="." type="text" />
            <span class="input__label">Nome</span>
          </label>
        </div>
        <div class="col-12 form-group pt-2">
       
          <label class="col-12 form-group ">
            <input class="input__field form-control" id="email" formControlName="email" placeholder="." type="email" />
            <span class="input__label">E-mail</span>
          </label>
        </div>
        <div class="col-12 form-group pt-2">
          <label class="col-12 form-group">
            <textarea class="input__field form-control" placeholder="." type="text" ></textarea>
            <span class="input__label">Mensagem</span>
          </label>
      
        </div>
        <div class="button d-flex justify-content-center pt-4">
          <button type="submit" class="btn btn-warning rounded-pill pl-5 pr-5 col-8  pt-2 pb-2  btn-block">Enviar</button>
        </div>
        <a routerLink="/login" style="text-align: center;display: block;font-weight: 700 !important;" class="pt-3">VOLTAR PARA O LOGIN</a>
      </div>
    </div>
  </form>
</div>

