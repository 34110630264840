import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {patterns} from '../../shared/helpers/patterns.helper';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.forgotPasswordForm = this.fb.group({});
  }

  onSubmit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])]
    });
  }

  ngOnInit(): void {}

}
