<div (window:resize)="checkWindowSize()" [class.smartphone]="isSmartphone" class="wrapper">
  <nav class="sidebar" [class.hide-sidebar]="hideSidebar">
    <div class="row m-0">
      <div class="col-12 nav-header mt-4 ml-3">
        <img src="../../../../assets/img/svg/logo-horizontal.svg" alt="lupe_web">
      </div>
      <div class="col-12  mb-4 pr-5 text-gerencial"> GERENCIAL </div>
      <div class="row w-100 m-0 only-nav">
        <ul class="menu">
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./user">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.087" viewBox="0 0 24 20.087">
                <g id="users" transform="translate(0 -1.913)">
                  <path id="Vector" d="M16,6V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V6" transform="translate(1 15)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-2" data-name="Vector" d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4Z" transform="translate(5 3)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-3" data-name="Vector" d="M3,5.87v-2a4,4,0,0,0-.839-2.448A4,4,0,0,0,0,0" transform="translate(20 15.13)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-4" data-name="Vector" d="M0,0A4,4,0,0,1,2.168,1.422a4,4,0,0,1,.84,2.453,4,4,0,0,1-.84,2.453A4,4,0,0,1,0,7.75" transform="translate(16 3.13)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>

              <div class="section-name pl-2">
                <span>Usuários</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./profissao">
              <svg xmlns="http://www.w3.org/2000/svg" width="24.414" height="20" viewBox="0 0 24.414 20">
                <g id="user-check" transform="translate(0 -2)">
                  <path id="Vector" d="M15,6V4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4V6" transform="translate(1 15)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-2" data-name="Vector" d="M8,4A4,4,0,1,1,4,0,4,4,0,0,1,8,4Z" transform="translate(4.5 3)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-3" data-name="Vector" d="M0,2,2,4,6,0" transform="translate(17 9)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>
              <div class="section-name pl-2">
                <span>Profissionais</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./servico">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
                <g id="file-plus" transform="translate(-3 -1)">
                  <path id="Vector" d="M10,0H2A2,2,0,0,0,0,2V18a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V6Z" transform="translate(4 2)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-2" data-name="Vector" d="M0,0V6H6" transform="translate(14 2)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-3" data-name="Vector" d="M0,6V0" transform="translate(12 12)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Vector-4" data-name="Vector" d="M0,0H6" transform="translate(9 15)" fill="none" stroke="#696a6b" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>

              <div class="section-name pl-2">
                <span>Serviços</span>
              </div>
            </a>
          </li>


          <div (click)="logout()" class="d-flex align-items-end footer" style="display: contents;">
            <a>
              <div class="section-name pl-2">
                <!-- <img src="../../../assets/img/png/curso.png" style="width:50px" alt=""> -->
              </div>
              <div class="section-name pl-2">
                <!-- <span class="m-0">{{ userName }}</span><Br> -->
                <p class="logout-text">SAIR</p>
              </div>
            </a>
          </div>

        </ul>
      </div>
    </div>
  </nav>
  <div [class.hide-sidebar]="hideSidebar" class="content">
    <div class="container-fluid min-vh-100 position-relative">
      <button (click)="hideSidebar = !hideSidebar" [class.d-none]="!isSmartphone" class="btn toggle">
        <i class="material-icons">view_headline</i>

      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>
