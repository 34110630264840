<div class="container-fluid d-flex justify-content-center">
  <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login form-row">

    <div class="card" style="width: 50rem;">
      <div class="card-body">
        <div class="col-12 d-flex justify-content-center logo">
          <img src="../../../assets/img/png/logoExterna.png" alt="">
        </div>
        <div class="d-flex justify-content-center logo">
          <strong style="text-align: center;color:#919294 !important;font-weight: 900 !important;"
            class="p-2  col-lg-12">GERENCIAL</strong>
        </div>
        <div class="pt-3">
          <h5 style="font-weight: 700 !important;">Seja bem vindo!</h5>
          <p>Para acessar a plataforma Lupe insira suas credenciais abaixo:</p>
        </div>
        <div class="col-12 form-group">
          <input type="email" placeholder="E-mail" formControlName="email" id="email" class="form-control">
        </div>
        <div class="col-12 form-group d-flex m-0">
          <input [type]="hide ? 'password' : 'text'" placeholder="Senha" formControlName="password" id="password"
            class="form-control" style="border-radius: 0.25rem
           0 0 0.25rem !important;">
          <div class="input-group-append">
            <span class="input-group-text" (click)="hide = !hide">
              <i class="material-icons" style="color:#919294">{{hide ? 'visibility_off' : 'visibility'}}</i>
            </span>
          </div>
        </div>
        <div class="pt-2">
          <a class="esquece pt-5" style="font-size: 14px !important;" routerLink="/recuperar-senha">Esqueci minha
            senha</a>
        </div>
        <div class="col-12 form-group button pt-5">
          <button type="submit" class="btn btn-warning rounded-pill pl-5 pr-5 pt-1 pb-1  btn-block p-2">Entrar</button>
        </div>
        <div class="col-12 mt-2 term">
          <a routerLink="/termos-de-uso">TERMOS DE USO</a>
          <a routerLink="/politicas-de-privacidade" class="pl-2"> POLÍTICA DE PRIVACIDADE</a>
          <a routerLink="/contato" class="pl-2 pr-3"> CONTATO</a>

        </div>
      </div>
    </div>
  </form>
</div>
