import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { patterns } from 'src/app/shared/helpers/patterns.helper';
import { TextsService } from '../services/texts.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.forgotPasswordForm = this.fb.group({});
  }

  onSubmit() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])]
    });
  }

  ngOnInit(): void {}

}
