import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TextsService } from '../services/texts.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  privacy

  constructor(
    private textService: TextsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    //this.start()
  }

  async start(){
    try{
      await this.getPrivacy()
    }catch{
      this.toastr.error('Não foi possível carregar os POLÍTICA de Privacidade', 'Error !')
    }
  }

  getPrivacy(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.textService.getPrivacyPolicy().subscribe(response =>{
        this.privacy = response.privacyPolicies
        resolve(response)
      }, error=>{
        reject(error)
      })
    })
  }
}
