
<div class="container-fluid d-flex justify-content-center">
  <form class="login form-row" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" >
    <div class="card" style="width: 50rem;">
      
      <div class="card-body">
        <div class="col-12 d-flex justify-content-center logo pt-5">
          <img src="../../../assets/img/png/logoExterna.png" alt="">
        </div>
        <div class="d-flex justify-content-center logo">
          <strong style="text-align: center;color:#919294 !important;font-weight: 900 !important;" class="p-2  col-lg-12">GERENCIAL</strong>
        </div>
        <div class="pt-5">
          <h6 class="bold mb-1">Recuperação de senha</h6>
          <p>Informe o e-mail associado à sua conta para 
            receber um link de redefinição de senha.
            </p>
        </div>
        <div class="col-12 form-group">
          <input type="email" placeholder="E-mail" id="email" class="form-control">
        </div>
        <div class="button d-flex justify-content-center pt-4">
        <button type="submit" class="btn btn-warning rounded-pill pl-5 pr-5 col-8  pt-2 pb-2  btn-block">Enviar</button>
        </div>
        <a routerLink="/login" style="text-align: center;display: block; font-weight: 700 !important;" class="pt-3">VOLTAR PARA O LOGIN</a>
      </div>
    </div>
  </form>
</div>

